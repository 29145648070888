import React from 'react'
import styled from 'styled-components'

import Layout from '../components/layout'
import our_logo from '../images/logo.svg'
import Button from '../components/button'
import { Row, Column } from '../components/grid'
import Headline from '../components/headline'
import Logo from '../components/logo'
import Container from '../components/container'
import Lead from '../components/lead'

const Center = styled.div`
  width: 100%;
  @media (min-width: 768px) {
    padding-top: 50px;
  }
`

const Address = styled.div`
  font-size: 12px;
  color: #666;
  padding: 30px 0;
  a {
    color: inherit;
    text-decoration: none;
  }
`

const Tell = styled.div`
  font-weight: 700;
  color: #fff;
  text-decoration: none;
  display: inline-block;
  padding: 5px 0;
  position: relative;
`

const IndexPage = () => (
  <Layout>
    <Container>
      <Center>
        <Row>
          <Column s={10} m={10} l={7} xl={4} lo={1}>
            <Logo
              src={our_logo}
              alt="Hustle & Praise"
              title="Hustle & Praise"
            />
            <Headline>
              Improving, tweaking, overhauling... or thinking of
              something new?
            </Headline>
          </Column>
        </Row>
        <Row>
          <Column s={6} m={6} l={5} xl={2} lo={1}>
            
            <Tell>
            Tell us more.{'  '}
              <Button
                href="mailto:studio@hustleandpraise.com"
                title="studio@hustleandpraise.com"
              >
                studio@hustleandpraise.com
              </Button>
            </Tell>


            <Lead>Shopify - Wordpress - Email Marketing</Lead>
          </Column>
        </Row>
        <Row>
          <Column s={10} m={10} l={6} xl={5} lo={1}>
            <Address>
              76 Thomas Street, Dublin 8, Ireland &bull; +353 (0)1 211 8980
              &bull; studio@hustleandpraise.com
            </Address>
          </Column>
        </Row>
      </Center>
    </Container>
  </Layout>
)

export default IndexPage
