import styled, { keyframes } from 'styled-components'
import React from 'react'

const letterslide = keyframes`
  from {
    -webkit-transform: translate(-10px, 0);
    opacity: 0;
    color:#E41352; 

  }

  to {
    -webkit-transform: translate(0, 0);
    opacity: 1;
    color:#fff; 
  }
`

const LeadLetter = styled.span`
  margin: 1em 0;
  color: #fff;
  font-weight: 700;
  font-size: 14px;
  animation: ${letterslide} 0.3s cubic-bezier(0.645, 0.045, 0.355, 1) both;
  animation-delay: ${props => props.delay || 0.1}s;
  &.alt {
    font-family: 'Playfair Display', serif;
    font-weight: normal;
  }
  @media (min-width: 375px) {
    font-size: 16px;
  }
`

const Lead = ({ children, className }) => (
  <p>
    {children.split('').map((letter, index) => (
      <LeadLetter key={index} delay={index / 50} className={className}>
        {letter}
      </LeadLetter>
    ))}
  </p>
)

export default Lead
