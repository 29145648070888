import styled, { keyframes } from 'styled-components'

const btn_line = keyframes`
  from {
    width: 0%;
  }

  to {
    width: 100%;
  }
`

const Button = styled.a`
  font-weight: 700;
  color: #fff;
  text-decoration: none;
  display: inline-block;
  padding: 5px 0;
  position: relative;
  &:after {
    content: '';
    display: block;
    width: 0%;
    height: 3px;
    position: absolute;
    left: 0;
    bottom: 0;
    background-color: #ffeb03;
    animation: ${btn_line} 3s cubic-bezier(0.645, 0.045, 0.355, 1) both;
  }
  @media(min-width: 1440px) {
    margin-left: 10px;
  }
`
export default Button
